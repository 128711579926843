<template>
  <div class="h-100">
    <Layout ref="layout" :full="true">
      <template v-slot:body>
        <div class="h-100 w-100 map">
          <Map ref="map">
            <template v-slot:body>
              <div class="w-100 h-100">
                <Aside ref="aside" @close="asideClose" @show="showAside">
                  <template v-slot:body>
                    <AsideClima
                      @info="
                        d.detalhado
                          ? ($refs.aside.footerButton = {
                              name: 'Dados detalhados',
                              icon: 'fa fa-circle-info',
                            })
                          : null
                      "
                      ref="aside_clima"
                      :key="'aside_clima_' + (asideClimaKey || 0)"
                      v-if="menuType == 'clima'"
                    ></AsideClima>
                    <AsideEstacaoSimples
                      ref="aside_estacao"
                      v-else
                    ></AsideEstacaoSimples>
                  </template>
                </Aside>
                <div class="w-100 h-100">
                  <div class="map-load" v-if="loadMap">
                    <div class="m-auto text-center">
                      <Load></Load>
                    </div>
                  </div>
                  <div v-if="menuType == 'clima'">
                    <div class="div-btn-mapa">
                      <button
                        :title="b.title"
                        v-for="(b, i) in climaBotoes"
                        :key="i"
                        :class="
                          'btn-mapa' + (b.value == climaType ? ' active' : '')
                        "
                        @click="climaType = b.value"
                      >
                        <font-awesome-icon
                          class="icone-card-clima"
                          :icon="b.icon"
                        />
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div class="div-btn-mapa">
                      <button
                        style="display: none"
                        :title="b.title"
                        v-for="(b, i) in estacaoBotoes"
                        :key="i"
                        :class="
                          'btn-mapa' + (b.value == estacaoType ? ' active' : '')
                        "
                        @click="estacaoType = b.value"
                      >
                        <font-awesome-icon
                          class="icone-card-clima"
                          :icon="b.icon"
                        />
                      </button>
                    </div>
                  </div>

                  <div class="div-btn-mapa-bottom">
                    <button
                      :title="b.title"
                      v-for="(b, i) in menuBotoes"
                      :key="i"
                      :class="
                        'btn-mapa inline' +
                        (b.value == menuType ? ' active' : '')
                      "
                      @click="
                        menuType = b.value;
                        $router.push(b.route);
                      "
                    >
                      <font-awesome-icon
                        v-if="b.icon"
                        class="icone-card-clima"
                        :icon="b.icon"
                      />
                      <LogoIcon v-if="b.eicon" class="btn-logo-icon"></LogoIcon>
                      <span v-if="b.value === menuType" class="button-title">
                        {{ b.title }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:point="data">
              <ClimaPonto :data="data"></ClimaPonto>
            </template>
          </Map>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../components/layout/Tema.vue";
import Map from "../components/mapa/Map.vue";
import ClimaPonto from "../components/mapa/ClimaPonto.vue";
import Aside from "../components/mapa/Aside.vue";
import AsideClima from "../components/mapa/AsideClima.vue";
import AsideEstacaoSimples from "../components/mapa/AsideEstacaoSimples.vue";
import Load from "../components/Load.vue";
import LogoIcon from "../components/LogoIcon.vue";
const StatusEnum = Object.freeze({
  Pendente: 1,
  Offline: 2,
  Online: 3,
});
export default {
  components: {
    Layout,
    Map,
    Aside,
    AsideClima,
    AsideEstacaoSimples,
    Load,
    LogoIcon,
    ClimaPonto,
  },
  data() {
    return {
      menuType: "estacao",
      run: null,
      accept: false,
      pontos: null,
      loadMap: false,
      chart: {
        cpu1: null,
        cpu2: null,
      },
      climaBotoes: [
        {
          title: "Temperatura",
          icon: "fa fa-thermometer-full",
          value: "temperatura",
        },
        {
          title: "Precipitação",
          icon: "fa fa-cloud-showers-heavy",
          value: "precipitacao_prevista",
        },
        { title: "Vento", icon: "fa fa-wind", value: "vento_velocidade" },
        { title: "Umidade", icon: "fa fa-tint", value: "umidade" },
      ],
      estacaoBotoes: [
        {
          title: "Temperatura",
          icon: "fa fa-thermometer-full",
          value: "temperatura_media",
        },
        {
          title: "Precipitação",
          icon: "fa fa-cloud-showers-heavy",
          value: "precipitacao_soma",
        },
        { title: "Vento", icon: "fa fa-wind", value: "vento_velocidade_media" },
        { title: "Umidade", icon: "fa fa-tint", value: "umidade_media" },
      ],
      menuBotoes: [
        {
          title: "Dispositivos",
          eicon: true,
          value: "estacao",
          route: "/?map=estacao",
        },
        {
          title: "Previsão",
          icon: "fa fa-cloud-sun",
          value: "clima",
          route: "/?map=clima",
        },
      ],
      info: {
        connection: "-",
        indicator: "-",
        chart: "-",
        table: "-",
        page: "-",
        panel: "-",
        filter: "-",
        user: "-",
      },
      osInfo: {
        memory: {},
        cpu: {},
      },
      values: [],
      selectedPoint: null,
      climaType: "temperatura",
      estacaoType: "temperatura_media",
      menuType: "estacao",
      climaAutoUpdate: null,
      estacaoAutoUpdate: null,
      asideClimaKey: 0,
    };
  },
  methods: {
    changeMap(type) {
      this.menuType = type;

      // Atualiza a URL com a query string correspondente
      this.$router.push({ path: "/", query: { map: type } });
    },
    asideClose() {
      this.selectedPoint = null;
    },
    showAside(s) {
      this.updatePontos();
    },
    clickPonto() {},

    updatePontos() {
      this.move(this.$refs.map, this.$refs.map.getInfo());
    },
    getType(type = this.menuType) {
      if (type == "clima") {
        return this.climaType;
      } else {
        return this.estacaoType;
      }
    },
    selectedColor() {
      if (this.menuType == "clima") {
        if (
          this.climaType == "precipitacao_prevista" ||
          this.climaType == "umidade"
        ) {
          return "rgb(255,0,0)";
        } else {
          return "rgb(0,0,255)";
        }
      } else {
        if (
          this.estacaoType == "precipitacao_soma" ||
          this.estacaoType == "umidade_media"
        ) {
          return "rgb(255,0,0)";
        } else {
          return "rgb(0,0,255)";
        }
      }
    },
    move(map, info) {
      if (this.pontos) {
        var points = map.getPontosFiltrado(
          this.pontos,
          info,
          30,
          this.selectedPoint
        );
        this.$refs.map.addPoints(points);
      }
    },
    rebuildAsideClima() {
      this.asideClimaKey = (this.asideClimaKey || 0) + 1;
    },
    clickClima(data) {
      this.$refs.map.move(
        data.latlng.lat,
        data.latlng.lng,
        this.$refs.map.getZoom()
      );

      if (this.menuType === "clima" && this.$refs.aside_clima && this.$refs.aside_clima.grafico) {
        this.$refs.aside_clima.grafico.destroy();
        this.$refs.aside_clima.grafico = null;
      }

      if (this.menuType == "estacao") {
        this.$refs.aside.fadeIn(
          [data.target.options.point.nome, data.target.options.point.county] );
      } else {
        this.$refs.aside.fadeIn(
          [ data.target.options.point.uf
              ? data.target.options.point.nome + " / " + data.target.options.point.uf
              : data.target.options.point.nome, "" ]
        );
      }
      this.$refs.aside.footerButton = null;
      if (this.menuType == "clima") {
        this.rebuildAsideClima();
        this.$nextTick(() => {
          if (this.$refs.aside_clima) {
            this.$refs.aside_clima.getDataFirstRender(
              data.target.options.point.cidade_id,
              data.target.options.point.data
            );
          }
        });
      } else {
        this.$refs.aside_estacao.getDataFirstRender(
          data.target.options.point.qrcodeid,
          data.target.options.point.data
        );
        if (data.target.options.point.detalhado) {
          this.$refs.aside.footerButton = {
            name: "Dados detalhados",
            icon: "fa fa-circle-info",
          };
          this.$refs.aside.$forceUpdate();
        }
      }
      var pinAdd =
        this.menuType == "estacao" &&
        data.target.options.point.status == StatusEnum.Online
          ? " pin-online"
          : "";
      data.target.options.icon.options.html = `<div class="square-pin-icon" title="${
        data.target.options.point
      }">
        <div class="square-pin-icon-container${pinAdd}">
        <div class="square-pin-icon-text-container" style="background-color: ${this.selectedColor()};">
        <span class="square-pin-icon-text">
        ${this.getClimaValueFormat(data.target.options.point[this.getType()])}
        </span>
        </div>
        </div>
        <div class="square-pin-icon-point"></div> </div>`;
      var icon = data.target.getIcon();
      data.target.setIcon(icon);
      this.selectedPoint = data.target.options.point;
    },
    getClimaValueFormat(v) {
      if (this.menuType == "clima") {
        switch (this.climaType) {
          case "umidade": {
            return v.format(0, 0) + "%";
          }
          case "temperatura": {
            return v.format(0, 0) + "º";
          }
          default: {
            return v.format(0, 1);
          }
        }
      } else {
        switch (this.estacaoType) {
          case "umidade_media": {
            return v.format(0, 0) + "%";
          }
          case "temperatura_media": {
            return v.format(0, 0) + "º";
          }
          default: {
            return v.format(0, 1);
          }
        }
      }
    },
    add(point, markerGroup, map) {
      //  debugger;
      var r = 75,
        g = 180,
        b = 21;
      var valor = parseInt(point[this.getType()]);
      if (
        this.menuType == "clima"
          ? this.climaType == "temperatura"
          : this.estacaoType == "temperatura_media"
      ) {
        if (valor <= -30) {
          r = 149;
          g = 185;
          b = 217;
        } else if (valor <= -15) {
          var valorTemp = valor - -30;
          var percent = (valorTemp * 100) / 15;
          r = Math.round(149 - (149 * percent) / 100);
          g = Math.round(185 - (104 * percent) / 100);
          b = Math.round(217 - (44 * percent) / 100);
        } else if (valor <= 5) {
          var valorTemp = valor - -15;
          var percent = (valorTemp * 100) / 20;
          r = Math.round(0 - (0 * percent) / 100);
          g = Math.round(81 + (127 * percent) / 100);
          b = Math.round(173 - (85 * percent) / 100);
        } else if (valor <= 15) {
          var valorTemp = valor - 5;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(0 + (255 * percent) / 100);
          g = Math.round(208 - (73 * percent) / 100);
          b = Math.round(88 - (88 * percent) / 100);
        } else if (valor <= 30) {
          var valorTemp = valor - 15;
          var percent = (valorTemp * 100) / 15;
          r = Math.round(255 - (7 * percent) / 100);
          g = Math.round(135 - (135 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else if (valor <= 40) {
          var valorTemp = valor - 30;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(248 - (92 * percent) / 100);
          g = Math.round(0 - (0 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else if (valor <= 50) {
          var valorTemp = valor - 40;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(156 - (31 * percent) / 100);
          g = Math.round(0 - (0 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else {
          r = 125;
          g = 0;
          b = 0;
        }
      } else {
        var m = valor;
        if (m > 150) {
          m = 150;
        }
        if (this.menuType == "clima") {
          switch (this.climaType) {
            case "vento_velocidade": {
              m *= 5;
              r = 150 + m;
              g = 150 - m;
              b = 150 - m;
              break;
            }
            case "umidade": {
              m *= 1;
              r = 150 - m;
              g = 150 - m;
              b = 150 + m;
              break;
            }
            default: {
              m *= 15;
              r = 150 - m;
              g = 150 - m;
              b = 150 + m;
            }
          }
        } else {
          switch (this.estacaoType) {
            case "vento_velocidade_media": {
              m *= 5;
              r = 150 + m;
              g = 150 - m;
              b = 150 - m;
              break;
            }
            case "umidade_media": {
              m *= 1;
              r = 150 - m;
              g = 150 - m;
              b = 150 + m;
              break;
            }
            default: {
              m *= 15;
              r = 150 - m;
              g = 150 - m;
              b = 150 + m;
            }
          }
        }
      }
      if (this.selectedPoint && point.id == this.selectedPoint.id) {
        if (this.menuType == "clima") {
          if (
            this.climaType == "precipitacao_prevista" ||
            this.climaType == "umidade"
          ) {
            r = 255;
            g = 0;
            b = 0;
          } else {
            r = 0;
            g = 0;
            b = 255;
          }
        } else {
          if (
            this.estacaoType == "precipitacao_soma" ||
            this.estacaoType == "umidade_media"
          ) {
            r = 255;
            g = 0;
            b = 0;
          } else {
            r = 0;
            g = 0;
            b = 255;
          }
        }
      }
      var htmlIcon =
        '<div class="square-pin-icon" title="' +
        point.nome +
        '"> ' +
        '    <div class="square-pin-icon-container' +
        (this.menuType == "estacao" && point.status == StatusEnum.Online
          ? " pin-online"
          : "") +
        '"> ' +
        '        <div class="square-pin-icon-text-container" style="background-color: rgb(' +
        r +
        "," +
        g +
        "," +
        b +
        ');"> ' +
        '            <span class="square-pin-icon-text">' +
        this.getClimaValueFormat(point[this.getType()]) +
        "</span> " +
        "        </div> " +
        "    </div> " +
        '    <div class="square-pin-icon-point"></div> ' +
        "</div>";
      if (this.menuType == "estacao" && point.bateriaOnConnect < 12.0) {
        htmlIcon += `<div class="battery-map-icon"></div>`;
      }
      var icone = L.divIcon({
        className: "",
        iconSize: [10, 10],
        html: htmlIcon,
      });

      L.marker([point.latitude, point.longitude], {
        point: point,
        icon: icone,
      })
        .addTo(markerGroup)
        .on("click", this.clickClima);
    },
    setPontos(type = this.menuType, load = true) {
      this.pontos = null;
      this.updatePontos();
      this.loadMap = load;
      clearInterval(this.climaAutoUpdate);
      clearInterval(this.estacaoAutoUpdate);
      if (type == "clima") {
        this.getClimaPontos()
          .then((e) => {
            if (this.menuType == "clima") {
              this.pontos = e.data;
              this.updatePontos();
              this.climaAutoUpdate = setInterval(() => {
                this.setPontos(this.menuType, false);
              }, 60000 * 10);
            }
          })
          .finally(() => {
            this.loadMap = false;
          });
      } else {
        this.getEstacaoPontos()
          .then((e) => {
            if (this.menuType == "estacao") {
              this.pontos = e.data;
              this.updatePontos();
              this.estacaoAutoUpdate = setInterval(() => {
                this.setPontos(this.menuType, false);
              }, 60000);
            }
          })
          .finally(() => {
            this.loadMap = false;
          });
      }
    },
  },
  mounted() {
    const queryParams = this.$route.query;
    if (!queryParams.map) {
      this.$router.push({ path: '/', query: { map: 'estacao' } });
    } else {
      this.menuType = queryParams.map;
    }
    this.$refs.map.event = {
      add: this.add,
      move: this.move,
    };
    this.setPontos();
  },
  beforeUnmount() {
    clearInterval(this.climaAutoUpdate);
    clearInterval(this.estacaoAutoUpdate);
  },
  watch: {
    climaType() {
      this.updatePontos();
    },
    estacaoType() {
      this.updatePontos();
    },
    menuType() {
      if (this.$refs.aside_clima && this.$refs.aside_clima.grafico) {
        this.$refs.aside_clima.grafico.destroy();
        this.$refs.aside_clima.grafico = null;
      }
      if (this.$refs.aside) {
          this.$refs.aside.show = false;
      }
      this.setPontos();
    },
    "$route.query.map": {
      handler(value) {
        this.menuType = value || "estacao";
      },
      immediate: true, // Executa no carregamento inicial
    },
  },
};
</script>

<style>
.leaflet-right .leaflet-control {
  margin-top: 50px !important;
}
.map-load {
  position: absolute;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #97979788;
  pointer-events: all;
}
.card-info {
  min-height: 188px;
}
.dash-proccess {
  max-height: 350px;
  overflow-y: auto;
}
.btn-mapa {
  height: 48px;
  width: 48px;
  margin-bottom: 10px;
  border-radius: 10%;
  font-size: 22px;
  outline-width: 0;
  border: 2px solid #b1b1b1;
  color: #b1b1b1;
  padding: 1px;
  display: flex;
  pointer-events: all;
}
.btn-mapa.inline {
  display: inline-flex;
  margin-right: 10px;
}
.btn-mapa svg {
  display: flex;
  margin: auto;
  font-size: 25px;
}
.btn-mapa.active {
  background-color: #006035;
  color: #fff;
  border-color: #006035;
}
.div-btn-mapa-bottom .btn-mapa.active {
  width: 175px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
}
.div-btn-mapa-bottom .btn-mapa.active svg {
  margin: 10px;
  font-size: 25px;
}
.div-btn-mapa-bottom .btn-mapa.active .button-title {
  margin-right: 10px;
  font-size: 18px;
}
.div-btn-mapa-bottom .btn-mapa.active .btn-logo-icon {
  display: contents;
}
.div-btn-mapa-bottom {
  position: absolute;
  display: flex;
  z-index: 1000;
  left: 10px;
  bottom: 0px;
  width: calc(100% - 10px);
}
.div-btn-mapa {
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 108px;
}
.pin-online {
  width: 36px;
  height: 27px;
  border-radius: 5px;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  /* border: 2px solid #08a13b; */
}
.pin-online::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#25a80b, #25a80b),
    linear-gradient(#4ed533, #4ed533), linear-gradient(#25a80b, #25a80b),
    linear-gradient(#4ed533, #4ed533);
  -webkit-animation: rotate 4s linear infinite;
  animation: rotate 2s linear infinite;
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.pin-online::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: white;
  border-radius: 5px;
}
.battery-map-icon {
  transform: rotate(-90deg);
  position: absolute;
  color: red;
  left: 18px;
  top: -20px;
  background-color: #fff;
}

.battery-map-icon {
  width: 25px;
  height: 10px;
  border: 2px solid red;
  position: relative;
  border-radius: 3px;
}

.battery-map-icon::before {
  position: absolute;
  content: "";
  width: 5px;
  background: red;
  /* 
    battery full
    width: 98%;
    background: greenyellow; 
    */
  height: 5px;
  top: 1px;
  left: 1px;
  border-radius: 2px;
  animation: blink-battery ease-in-out 1s infinite;
}

.battery-map-icon::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 5px;
  background: red;
  right: -3px;
  top: 1px;
  border-radius: 2px;
}

@keyframes blink-battery {
  from {
    background: transparent;
  }
  to {
    background: red;
  }
}
</style>
