<template>
  <div :class="load ? 'd-flex' : ''">
    <div class="pt-2" v-show="!load">
      <div class="clima-card" v-if="info">
        <div class="text-center px-1 mt-0">
          <div
            v-for="(d, i) in info.days"
            :key="i"
            :style="
              'display: inline-block; width: ' + 100 / info.days.length + '%;'
            "
          >
            <button
              title="Quinta-feira"
              :class="'botao-tempo-dia px-0' + (i == active ? ' ativo' : '')"
              @click="active = i"
            >
              <span style="font-size: 11px"
                >{{ week[new Date(d.cidade_clima.data).getDay()].abr }}
                {{ new Date(d.cidade_clima.data).getDate() }}</span
              >
              <span class="d-block" style="font-size: 16px"
                >{{
                  getPrecipitacaoFaltante(
                    d.cidade_clima.precipitacao_total, i
                  ).format(0, 1)
                }}
              </span>
              <span class="d-block" style="font-size: 10px; margin-top: -7px"
                >mm</span
              >
              <div class="row mx-0" style="margin-top: -3px">
                <div class="col-6 px-0">
                  <span style="font-size: 13px"
                    >{{ d.cidade_clima.temperatura_max.format(0, 0) }}º</span
                  >
                </div>
                <div class="col-6 px-0">
                  <span style="font-size: 13px;"
                    >{{ d.cidade_clima.temperatura_min.format(0, 0) }}º</span
                  >
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="clima-card" v-if="info">
        <div>
          <div
            style=""
            v-for="(d, i) in info.days"
            :key="i"
            v-show="i == active && hour"
          >
            <span class="temperatura-atual-texto text-center" v-if="hour"
              >Previsão para
              {{ new Date(hour.data).toLocaleString('pt-BR').substring(0, 17) }}</span
            >
            <div v-if="hour">
              <div class="row mx-0 my-2">
                <div class="col-12">
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-cloud-showers-heavy fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{
                            getPrecipitacaoFaltante(hour.precipitacao, i).format(
                              0,
                              1
                            )
                          }}
                          mm</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-wind fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.vento_velocidade.format(0, 1) }} km/h</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-tint fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.umidade.format(0, 0) }} %</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-flag fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima">{{
                          hour.vento_vetorial.replaceAll("W", "O")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-clock fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.pressao.format(0, 0) }} hPa</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="row mini-card m-0">
                  <div class="col-6 px-1">
                    <div class="p-0 d-flex">
                      <span class="temperatura-atual m-auto"
                        >{{ hour.temperatura.format(0, 0) }}º</span
                      >
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div>
                      <div class="d-flex m-0">
                        <div class="dados-clima-max-min">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              style="color: red"
                              icon="fa fa-thermometer-full fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Máxima</span>
                          <span class="texto-dados-clima-min-max" 
                            >{{
                              d.cidade_clima.temperatura_max.format(0, 0)
                            }}º</span
                          >
                        </div>
                      </div>
                      <div class="d-flex m-0">
                        <div class="dados-clima-max-min">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              style="color: #82b1ff"
                              icon="fa fa-thermometer-empty fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Mínima</span>
                          <span class="texto-dados-clima-min-max"
                            >{{
                              d.cidade_clima.temperatura_min.format(0, 0)
                            }}º</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clima-card text-center" v-show="info">
        <div class="mt-2" v-if="info">
          <!--carrocel-->
          <div style="margin-left: 10px">
            <Splide
              ref="splide"
              :options="{
                rewind: true,
                type: 'loop',
                start: 0,
                perPage: 5,
                focus: 'center',
                pagination: false,
                isNavigation: true,
                arrows: true,
                drag: true,
                arrowPath:
                  'M9.2118 18.3608H27.6138L19.5742 10.3213C18.9317 9.67875 18.9317 8.62438 19.5742 7.98188C20.2167 7.33937 21.2546 7.33937 21.8971 7.98188L32.7538 18.8385C33.3963 19.4811 33.3963 20.5189 32.7538 21.1614L21.8971 32.0181C21.2546 32.6606 20.2167 32.6606 19.5742 32.0181C18.9317 31.3756 18.9317 30.3377 19.5742 29.6952L27.6138 21.6557H9.2118C8.30571 21.6557 7.56435 20.9143 7.56435 20.0082C7.56435 19.1021 8.30571 18.3608 9.2118 18.3608Z',
              }"
              @splide:active="onSlideChange"
              @splide:mounted="onSplideMounted"
            >
              <!--Temperatura-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\Temperatura.svg"
                    alt="temperatura"
                  />
                </div>
              </SplideSlide>
              <!--Chuva-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\Chuva.svg"
                    alt="chuva"
                  />
                </div>
              </SplideSlide>
              <!--Vento-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\Vento.svg"
                    alt="vento"
                  />
                </div>
              </SplideSlide>
              <!--Umidade-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\Umidade.svg"
                    alt="umidade"
                  />
                </div>
              </SplideSlide>
              <!--Pressão-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\Barometro.svg"
                    alt="barometro"
                  />
                </div>
              </SplideSlide>
              <!--Delta-T-->
              <SplideSlide>
                <div class="slideStyle">
                  <img
                    src="../mapa/src/components/mapa/stationIcon/deltat/IDeltaT.svg"
                    alt="Delta-T"
                  />
                </div>
              </SplideSlide>
            </Splide>
            <div style="margin-top: 2px; margin-right: 12px; text-align: center">
              <span>{{ carrocelInfoClima }}</span>
              <span
                  v-if="carrocelInfoClima === 'Delta-T'"
                  style="
                    display: inline-block;
                    margin-left: 5px;
                    background-color: crimson;
                    color: #FFF;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 2px 8px;
                    border-radius: 12px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                  "
                >
                  Beta
                </span>
            </div>
          </div>
        </div>

        <div v-if="carrocelInfoClima === 'Delta-T'" class="legenda-container">
          <div class="legenda-item">
            <div class="legenda-cor cor-adequado"></div>
            <div class="legenda-texto">
              Condições mais adequadas
            </div>
          </div>
          
          <div class="legenda-item">
            <div class="legenda-cor cor-inadequado"></div>
            <div class="legenda-texto">
              Inadequado para gotas médias, finas e muito finas
            </div>
          </div>
          
          <div class="legenda-item">
            <div class="legenda-cor cor-limite"></div>
            <div class="legenda-texto">
              Condições limite
            </div>
          </div>
          
          <div class="legenda-item">
            <div class="legenda-cor cor-nao-recomendado"></div>
            <div class="legenda-texto">
              Condições não recomendadas
            </div>
          </div>
        </div>

        <div :class="['mb-0', 'mini-card', carrocelInfoClima !== 'Delta-T' ? 'mt-4' : '']">
          <canvas ref="grafico_clima" width="310" height="175"></canvas>
        </div>
      </div>

      <div class="clima-card" v-if="info">
        <div class="mini-card mt-2">
          <div class="text-center m-0">
            <div class="row m-0 px-1">
              <div class="d-inline clima-celula">
                <i class="fa fa-clock d-block"></i>
                Hora
                <span class="d-block">(hs)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-thermometer-full d-block"></i>
                Temp
                <span class="d-block">(ºC)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-cloud-showers-heavy d-block"></i>
                Chuva
                <span class="d-block">(mm)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-wind d-block"></i>
                Vento
                <span class="d-block">(km/h)</span>
              </div>
              <div class="d-inline clima-celula final">
                <i class="fa fa-tint d-block"></i>
                Umid
                <span class="d-block">(%)</span>
              </div>
            </div>
            <div
              class="row m-0 px-1"
              v-for="(d, i) in getFilterData(info.days[this.active].data)"
              :key="i"
            >
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ new Date(d.data).toLocaleTimeString('pt-BR').substring(0, 5) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.temperatura.format(0, 0) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.precipitacao.format(0, 1) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.vento_velocidade.format(0, 1) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula final' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.umidade.format(0, 0) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="hour"
          class="w-100"
          style="text-align: center; color: rgb(0, 96, 53)"
        >
          <small
            >Ultima atualização:
            {{ new Date(hour.created).toLocaleString() }}</small
          >
        </div>
        <div class="mt-3 text-center px-3">
          <small style="color: rgb(0, 96, 53)">weatherapi</small>
        </div>
      </div>
      <!---->
    </div>
    <div v-if="load" class="text-center m-auto p-5">
      <Load color="#006035"></Load>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/dist/css/themes/splide-default.min.css";
import Load from "../Load.vue";

export default {
  components: {
    Splide,
    SplideSlide,
    Load,
  },
  data() {
    return {
      active: null,
      carrocelInfoClima: "Temperatura",
      info: null,
      hour: null,
      grafico: null,
      load: true,
      data: null,
    };
  },
  methods: {
    onSplideMounted() {
      setTimeout(() => {
        this.$refs.splide.splide.refresh();
      }, 100);
    },
    onSlideChange(splide) {
      switch(splide.index) {
        case 0:
          this.carrocelInfoClima = 'Temperatura';
          break;
        case 1:
          this.carrocelInfoClima = 'Chuva';
          break;
        case 2:
          this.carrocelInfoClima = 'Velocidade vento';
          break;
        case 3:
          this.carrocelInfoClima = 'Umidade';
          break;
        case 4:
          this.carrocelInfoClima = 'Pressão';
          break;
        case 5:
          this.carrocelInfoClima = 'Delta-T';
          break;
      }
    },
    getAdequado(deltaT) {
      if (deltaT < 1) {
        return "Ruim";
      } else if (deltaT >= 1 && deltaT < 2) {
        return "Regular";
      } else if (deltaT >= 2 && deltaT < 4) {
        return "Adequado";
      } else if (deltaT >= 4 && deltaT < 6) {
        return "Adequado";
      } else if (deltaT >= 6 && deltaT <= 8) {
        return "Adequado";
      } else if (deltaT >= 8 && deltaT < 10) {
        return "Regular";
      } else if (deltaT >= 10 && deltaT < 12) {
        return "Inadequado";
      } else {
        return "Ruim";
      }
    },
    calculateDeltaT(hourData) {
      if (!hourData) return null;
      
      const { temperatura, umidade } = hourData;
      
      // Fórmula para calcular o ponto de orvalho
      const pontoOrvalho = temperatura * Math.atan(0.151977 * Math.sqrt(umidade + 8.313659)) +
        Math.atan(temperatura + umidade) -
        Math.atan(umidade - 1.676331) +
        0.00391838 * Math.pow(umidade, 1.5) * Math.atan(0.023101 * umidade) -
        4.686035;
      
      // Delta-T (Temperatura - Ponto de Orvalho)
      return Number((temperatura - pontoOrvalho).toFixed(2));
    },
    getCorDeltaT(deltaT) {
      const status = this.getAdequado(deltaT);

      switch(status) {
        case "Ruim": 
          return "#f45a51";
        case "Regular": 
          return "#f9e862";
        case "Inadequado": 
          return "#fda700";
        case "Adequado": 
          return "#5eb965";
        default:
          return "#727272";
      }
    },
    getFilterData(d) {
      if (this.data != null) {
        var nd = [];
        var now = new Date(this.data).getTime();

        for (const da of d) {
          // debugger;
          var di = new Date(da.data).getTime();
          if (di >= now) {
            nd.push(da);
          }
        }
        return nd;
      } else {
        return d;
      }
    },
    getPrecipitacaoFaltante(total, tab) {
      // debugger
      var now = new Date(this.data).getTime();

      var horas = this.info.days[tab].data;
      for (const da of horas) {
        var di = new Date(da.data).getTime();

        if (di < now) {
          total -= da.precipitacao;
        }
      }
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    getDataFirstRender(cidadeId, data) {
      this.getData(cidadeId, data);
    },
    getData(cidadeId, data) {
      clearTimeout(this.next);
      this.data = data;
      this.load = true;
      this.next = setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
        this.info = null;
        this.active = null;
        this.urlGet("/WeatherForecast/GetWeatherForecastById/" + cidadeId)
          .then((e) => {
            this.info = e.data;

            var now = new Date(data).getDate();

            for (let i = 0; i < this.info.days.length; i++) {
              var d = new Date(this.info.days[i].cidade_clima.data).getDate();
              if (d == now) {
                this.active = i;
                break;
              }
            }
            if (this.active == null) {
              this.active = 0;
            }
          })
          .catch((e) => {})
          .finally(() => {
            this.load = false;
          });
      }, 300);
    },
    updateChart() {
      if (!this.$refs.grafico_clima) {
        return;
      }

      var cor = "";
      var label = "";
      var type = "line";
      var labels = [];
      var data = [];
      let backgroundColor = [];
      var field = "";
      var min = 0;

      if (this.grafico) {
        this.grafico.destroy();
        this.grafico = null;
      }

      switch (this.carrocelInfoClima) {
        case "Temperatura": {
          field = "temperatura";
          label = "Temperatura (°C)";
          cor = "#F49C18";
          break;
        }
        case "Chuva": {
          type = "bar";
          field = "precipitacao";
          label = "Chuva (mm)";
          cor = "#BBD1FF";
          break;
        }
        case "Velocidade vento": {
          field = "vento_velocidade";
          label = "Vento (km/h)";
          cor = "#727272";
          break;
        }
        case "Umidade": {
          field = "umidade";
          label = "Umidade (%)";
          cor = "#3E97FF";
          break;
        }
        case "Pressão": {
          field = "pressao";
          label = "Pressão (hPa)";
          cor = "#FF3E3E";
          min = null;
          break;
        }
        case "Delta-T": {
          field = "delta_t";
          label = "Delta-T";
          type = "bar";
          cor = "#FF3E3E";
          min = null;
          break;
        }
      }

      // Verifica se temos dados e o índice ativo está definido
      if (this.info && this.active !== null && this.info.days[this.active] && this.info.days[this.active].data) {
        const dadosProcessados = this.info.days[this.active].data.map(h => {
          const horario = new Date(h.data).toLocaleTimeString('pt-BR').substring(0, 5);
          labels.push(horario);

          // Processamento especial para Delta-T
          if (this.carrocelInfoClima === "Delta-T") {
            const deltaT = this.calculateDeltaT(h);
            
            // Adiciona cor para cada barra de Delta-T
            if (deltaT !== null) {
              backgroundColor.push(this.getCorDeltaT(deltaT));
              return deltaT;
            }
            
            return null;
          }

          // Para outros campos, usa o método original
          return h[field] !== undefined ? h[field] : null;
        });

        data = dadosProcessados;
      } else {
        console.warn("Dados indisponíveis para gerar o gráfico");
        return;
      }

      if (!this.grafico) {
        try {
          this.grafico = new Chart(this.$refs.grafico_clima, {
            type: type,
            data: {
              labels: labels,
              datasets: [
                {
                  fill: true,
                  borderColor: this.carrocelInfoClima === "Delta-T" ? backgroundColor : cor,
                  backgroundColor: this.carrocelInfoClima === "Delta-T" ? backgroundColor : cor + "99",
                  label: label,
                  data: data,
                },
              ],
            },
            options: {
              animation: {
                duration: 300,
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: min == 0,
                      min: min == null ? undefined : min,
                    },
                  },
                ],
              },
              legend: {
                display: true,
                labels: {
                  generateLabels: (chart) => {
                    if (this.carrocelInfoClima === "Delta-T") {
                      return [
                      ];
                    }
                    return Chart.defaults.global.legend.labels.generateLabels(chart);
                  },
                },
              },
            },
          });
        } catch (ex) {
          console.error("Erro ao criar gráfico:", ex);
        }
      } else {
        this.grafico.config.type = type;
        this.grafico.data.labels = labels;
        this.grafico.data.datasets[0].label = label;
        this.grafico.data.datasets[0].data = data;
        this.grafico.data.datasets[0].borderColor = cor;
        
        // Define cores personalizadas para Delta-T
        if (this.carrocelInfoClima === "Delta-T") {
          this.grafico.data.datasets[0].backgroundColor = backgroundColor;
        } else {
          this.grafico.data.datasets[0].backgroundColor = cor + "99";
        }
        
        this.grafico.options.scales.yAxes[0].ticks.beginAtZero = min == 0;
        this.grafico.options.scales.yAxes[0].ticks.min = min == null ? undefined : min;
        this.grafico.update();
      }
    },
    updateHour() {
      var now = new Date().getHours();
      var d = null;
      for (const day of this.info.days[this.active].data) {
        var t = new Date(day.data).getHours();
        if (t > now) {
          break;
        }
        d = day;
      }
      if (d == null) {
        d = this.info.days[this.active].data[0];
      }
      this.hour = d;
      setTimeout(() => {
        this.updateChart();
      }, 10);
    },
    beforeUnmount() {
      setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
      }, 300);
    },
  },
  watch: {
    carrocelInfoClima() {
      this.$nextTick(() => {
        this.updateChart();
      });
    },
    active(a) {
      if (a != null) {
        this.updateHour();
      }
    },
  },
};
</script>

<style>
.clima-card {
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.legenda-container {
  margin: 20px 10px 0px 10px;
  padding: 5px;
}

.legenda-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.legenda-cor {
  width: 20px;
  height: 20px;
  min-width: 20px;
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
}

.legenda-texto {
  flex: 1;
  text-align: start;
}

.cor-adequado {
  background-color: #5eb965;
}

.cor-inadequado {
  background-color: #fda700;
}

.cor-limite {
  background-color: #f9e862;
}

.cor-nao-recomendado {
  background-color: #f45a51;
}

.splide__slide.is-active .slideStyle {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid #53703c;
  background: #53703c;
  display: grid;
  place-items: center;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2);
}
.splide__slide:not(.is-active) .slideStyle {
  background-color: #b0b0b0;
  border: 2px solid var(--Branco-75, #b0b0b0);
}
.splide__arrow {
  border-radius: 5px;
  background-color: #78a64e;
  margin-top: 40px;
}
.splide__arrow--next {
  right: 0.1em;
  margin-right: 10px;
}
.splide__arrow--prev {
  left: 0.1em;
  margin-right: 20px;
}
.splide__arrow:not(:disabled) {
  opacity: 1;
}
.splide__arrow:hover:not(:disabled) {
  opacity: 1;
}
.splide__track--nav .splide__list .splide__slide.is-active {
  border: none;
  outline: none;
}
.splide__arrow svg {
  fill: white;
}
.splide__slide .slideStyle {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid var(--Branco-75, #d1d1d1);
  background: #ffffff;
  display: grid;
  place-items: center;
}

.botao-tempo-dia {
  border: 1px solid #989998;
  background-color: #fff;
  height: auto;
  width: 58px;
  /* box-shadow: 0px 0px 8px 7px rgb(30 30 30 / 10%); */
  cursor: pointer;
  border-radius: 10%;
  color: #989998;
  font-size: 18px;
  font-weight: 510;
  outline: none !important;
  box-shadow: 0px 0px 0.1em #000000;
}

.temperatura-atual-mm {
  font-size: 18px;
  color: #006035;
  text-align: left;
  display: inline;
}

.temperatura-atual-texto {
  font-size: 14px;
  color: #006035;
  display: block;
  font-weight: 600;
}
.mini-card {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  /*box-shadow: 0px 0px 0.2em #000000;*/
  overflow: visible;
}

.dados-clima-inferior {
  width: 95%;
  display: inline-block;
  color: #0e9d27;
}

.botao-tempo-dia.ativo {
  color: #ffff;
  background-color: #006035;
  border: 1px solid #006035;
}
.temperatura-atual {
  color: #006035;
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  /* text-shadow: 3px 3px #00603544; */
}
.clima-celula.top {
  border-top: 1px solid #006035;
}

.clima-celula {
  width: 20%;
  border-bottom: 1px solid #006035;
  border-right: 1px solid #006035;
  font-size: 12px;
  color: #006035;
}

.clima-celula i {
  font-size: 16px;
}

.clima-celula.final {
  border-right: 0px;
}

.clima-celula.baixo {
  border-bottom: 0px;
}

.texto-dados-clima-min-max {
  font-size: 14px;
  display: block;
  margin-left: 5px;
  color: #006035;
  font-weight: 600;
}

.texto-clima-min-max {
  font-size: 14px;
  display: block;
  margin-left: 5px;
  color: #006035;
  font-weight: 600;
}
.dados-clima-max-min {
  font-size: 20px;
  display: block;
  margin-left: 5px;
  color: rgb(15, 157, 39);
}
.icon-clima {
  font-size: 24px;
}
.previsao-item-clima {
  width: 20%;
  display: inline-block;
}
.texto-dados-clima {
  font-size: 11px;
}
.botao-tempo-dia-grafico {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 10%;
  color: #989998;
  font-size: 18px;
  font-weight: 510;
  outline: none !important;
  box-shadow: 0px 0px 0.1em #000000;
  border: 1px solid #989998;
  background-color: #fff;
}
.botao-tempo-dia-grafico.ativo {
  color: #ffff;
  background-color: #006035;
  border: 1px solid #006035;
}
</style>