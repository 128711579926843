<template>
  <div :class="load ? 'd-flex' : ''">
    <div class="pt-2" v-show="!load">
      <div class="clima-card" v-if="info">
        <div class="text-center px-1 mt-0">
          <div style="text-align: left">
            <span style="font-size: small; font-weight: 600"
              >Selecione o sensor para visualização</span
            >
            <SelectData
              :url="'/Station/GetSensorByQrCodeId/' + this.qrCodeId"
              field="nome"
              label="nome"
              :value="this.station.nome"
              @value="this.station.nome = $event"
              @change="setCarouselBySensor()"
            >
            </SelectData>
          </div>
          <div style="text-align: left"></div>
        </div>
        <!--temperaturas-->
        <div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span style="margin-left: 3px; font-weight: 600; font-size: medium"
              >Histórico de leitura</span
            >
            <div
              v-for="(d, i) in info.days[this.active].hours"
              :key="i"
              style="margin-right: 5px"
            >
              <img
                v-if="
                  this.hour.dateTime === d.dateTime &&
                  d.battery == 1 &&
                  this.station.nome == 'Estação'
                "
                src="..\mapa\src\components\mapa\stationIcon\BateriaFull.svg"
                alt="bateriaFull"
              />
              <img
                v-if="
                  this.hour.dateTime === d.dateTime &&
                  d.battery == 0 &&
                  this.station.nome == 'Estação'
                "
                src="..\mapa\src\components\mapa\stationIcon\BateriaAlerta.svg"
                alt="bateriaFull"
              />
            </div>
          </div>
          <div class="text-center px-1 mt-0">
            <div
              v-for="(d, i) in info.days"
              :key="i"
              :style="
                'display: inline-block; width: ' + 100 / info.days.length + '%;'
              "
            >
              <button
                title="Quinta-feira"
                style="margin-top: 10px; margin-bottom: 10px"
                :class="'botao-tempo-dia px-0' + (i == active ? ' ativo' : '')"
                @click="active = i"
              >
                <span style="font-size: 11px">
                  {{ week[new Date(d.date).getDay()].abr }}
                  {{ new Date(d.date).getDate() }}
                </span>
                <div v-if="this.station.nome == 'Estação'">
                  <span class="d-block" style="font-size: 16px">
                    {{ d.resume.rainfallTotal.toFixed(2) }}
                  </span>
                  <span
                    class="d-block"
                    style="font-size: 10px; margin-top: -7px"
                    >mm</span
                  >
                  <div class="row mx-0">
                    <div class="col-6 px-0">
                      <span style="font-size: 13px">
                        {{ formatNumber(d.resume.temperatureMax) }}º
                      </span>
                    </div>
                    <div class="col-6 px-0">
                      <span style="font-size: 13px">
                        {{ formatNumber(d.resume.temperatureMin) }}º
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="this.station.nome == 'Sonda Hidrostática'">
                  <span class="d-block" style="font-size: 14px">
                    {{ d.resume.depthMax.toFixed(2) }}m
                  </span>
                  <span class="d-block" style="font-size: 14px"
                    >{{ d.resume.depthMin.toFixed(2) }}m
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <!--Ultima Leitura-->
        <div class="mini-card" v-if="hour">
          <div style="display: flex; justify-content: space-around">
            <div style="margin-bottom: 5px; width: 38%">
              <span
                style="margin-left: 3px; font-weight: 600; font-size: medium"
                >Última leitura</span
              >
            </div>
            <div style="display: flex; justify-content: space-around">
              <span style="margin-right: 5px; margin-left: 25px">
                {{ new Date(hour.dateTime).toLocaleString('pt-BR').split(", ")[0] }}
              </span>
              <img
                style="margin-bottom: 29px"
                src="..\mapa\src\components\mapa\stationIcon\Relogio.svg"
                alt="clock"
              />
              <span style="margin-left: 2px">
                {{
                  new Date(hour.dateTimeLeitura).toLocaleTimeString('pt-BR', {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}
              </span>
            </div>
          </div>
        </div>
        <!--Resumo das Temperaturas-->
        <div v-if="hour">
          <div
            style=""
            v-for="(d, i) in info.days"
            :key="i"
            v-show="i == active && hour"
          >
            <div>
              <div class="col-12" v-if="this.station.nome == 'Estação'">
                <div class="row mx-0">
                  <!--umidade-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        src="..\mapa\src\components\mapa\stationIcon\UmidadeBlack.svg"
                        alt="Umidade"
                      />
                      <div class="text-center icon-text">
                        <span class="texto-dados-clima"
                          >{{ hour.humidity }}%</span
                        >
                      </div>
                    </div>
                  </div>
                  <!--chuva-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        src="..\mapa\src\components\mapa\stationIcon\ChuvaBlack.svg"
                        alt="chuva"
                      />
                      <div class="text-center icon-text">
                        <span class="texto-dados-clima"
                          >{{ hour.rainfall.toFixed(2) }}
                          <span class="d-block mini-text">mm</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <!--barometro-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        src="..\mapa\src\components\mapa\stationIcon\BarometroBlack.svg"
                        alt="barometro"
                      />
                      <div class="text-center icon-text">
                        <span class="texto-dados-clima"
                          >{{ hour.pressureInHpa }}
                          <span class="d-block mini-text">hPa</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <!--Vento-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        src="..\mapa\src\components\mapa\stationIcon\VentoBlack.svg"
                        alt="vento"
                      />
                      <div class="text-center icon-text">
                        <span class="texto-dados-clima"
                          >{{ hour.windSpeed.toFixed(2) }}
                          <span class="d-block mini-text">km/h</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <!--direcao vento-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        :src="
                          require(`../mapa/src/components/mapa/stationIcon/direcao/${hour.windDirection}.svg`)
                        "
                        alt="direcao"
                      />
                      <div class="icon-text">
                        <span class="texto-dados-clima">{{
                          directionAbbreviation(hour.windDirection)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!--Uv-->
                  <div class="previsao-item">
                    <div class="icons">
                      <img
                        src="..\mapa\src\components\mapa\stationIcon\UvBlack.svg"
                        alt="uv"
                      />
                      <div class="text-center icon-text">
                        <span class="texto-dados-clima"
                          >{{ hour.uvi }}
                          <span class="d-block mini-text">UVI</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row mini-card m-0">
                  <div class="col-6 px-1">
                    <div
                      class="p-0 d-flex"
                      v-if="this.station.nome == 'Estação'"
                    >
                      <span class="temperatura-atual m-auto"
                        >{{ hour.temperature.toFixed(2) }}º</span
                      >
                    </div>
                    <div
                      v-if="this.station.nome == 'Sonda Hidrostática'"
                      class="d-flex flex-column align-items-center"
                    >
                      <span class="temperatura-atual m-auto">{{
                        hour.depthCalc.toFixed(2)
                      }}</span>
                      <span
                        style="
                          margin-top: -40px;
                          color: #006035;
                          text-align: center;
                          font-size: 40px;
                          font-weight: 800;
                        "
                        >m</span
                      >
                    </div>
                  </div>

                  <div class="col-3 px-0">
                    <div style="margin-left: 60px">
                      <div
                        v-if="this.station.nome == 'Estação'"
                        class="d-flex m-0"
                      >
                        <div class="dados-clima-max-min" style="color: red">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              icon="fa fa-thermometer-full fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Máxima</span>
                          <span class="texto-dados-clima-min-max">{{
                            d.resume.temperatureMax.toFixed(2)
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="d-flex m-0"
                        v-if="this.station.nome == 'Estação'"
                      >
                        <div class="dados-clima-max-min" style="color: #82b1ff">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              icon="fa fa-thermometer-empty fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Mínima</span>
                          <span class="texto-dados-clima-min-max">{{
                            d.resume.temperatureMin.toFixed(2)
                          }}</span>
                        </div>
                      </div>
                      <div
                        v-if="this.station.nome == 'Sonda Hidrostática'"
                        style="margin-top: 15px; margin-left: -30px"
                      >
                        <div class="d-flex m-0">
                          <div class="dados-clima-max-min">
                            <div class="d-flex h-100">
                              <img
                                class="icon-clima m-auto"
                                src="..\mapa\src\components\mapa\stationIcon\NivelLiquidoBlack.svg"
                                alt="nivel"
                              />
                            </div>
                          </div>
                          <div class="dados-clima-max-min">
                            <span class="texto-clima-min-max">Máxima</span>
                            <span class="texto-dados-clima-min-max"
                              >{{ d.resume.depthMax.toFixed(2) }}m</span
                            >
                          </div>
                        </div>
                        <div class="d-flex m-0">
                          <div class="dados-clima-max-min">
                            <div class="d-flex h-100">
                              <img
                                class="icon-clima m-auto"
                                src="..\mapa\src\components\mapa\stationIcon\NivelLiquidoGreen.svg"
                                alt="nivel"
                              />
                            </div>
                          </div>
                          <div class="dados-clima-max-min">
                            <span class="texto-clima-min-max">Mínima</span>
                            <span class="texto-dados-clima-min-max"
                              >{{ d.resume.depthMin.toFixed(2) }}m</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Premium Check-->
      <div v-if="this.premium">
        <!--carrocel-->
        <div class="clima-card">
          <span style="margin-left: 5px; font-weight: 600; font-size: medium"
            >Dados para visualização</span
          >
          <div>
            <div
              style="margin-left: 10px"
              v-show="this.station.nome == 'Estação'"
            >
              <Splide
                ref="splide"
                :options="{
                  rewind: true,
                  type: 'loop',
                  start: 1,
                  perPage: 5,
                  focus: 'center',
                  pagination: false,
                  isNavigation: true,
                  arrows: true,
                  arrowPath:
                    'M9.2118 18.3608H27.6138L19.5742 10.3213C18.9317 9.67875 18.9317 8.62438 19.5742 7.98188C20.2167 7.33937 21.2546 7.33937 21.8971 7.98188L32.7538 18.8385C33.3963 19.4811 33.3963 20.5189 32.7538 21.1614L21.8971 32.0181C21.2546 32.6606 20.2167 32.6606 19.5742 32.0181C18.9317 31.3756 18.9317 30.3377 19.5742 29.6952L27.6138 21.6557H9.2118C8.30571 21.6557 7.56435 20.9143 7.56435 20.0082C7.56435 19.1021 8.30571 18.3608 9.2118 18.3608Z',
                }"
                @splide:active="onSlideChange"
              >
                <!--Temperatura-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Temperatura.svg"
                      alt="temperatura"
                    />
                  </div>
                </SplideSlide>
                <!--Station-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Estacao.svg"
                      alt="estacao"
                    />
                  </div>
                </SplideSlide>
                <!--Umidade-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Umidade.svg"
                      alt="umidade"
                    />
                  </div>
                </SplideSlide>
                <!--Barometro-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Barometro.svg"
                      alt="barometro"
                    />
                  </div>
                </SplideSlide>
                <!--Vento-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Vento.svg"
                      alt="vento"
                    />
                  </div>
                </SplideSlide>
                <!--Rajada-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\RajadaVento.svg"
                      alt="rajada"
                    />
                  </div>
                </SplideSlide>
                <!--Chuva-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Chuva.svg"
                      alt="chuva"
                    />
                  </div>
                </SplideSlide>
                <!--Uv-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Uv.svg"
                      alt="uv"
                    />
                  </div>
                </SplideSlide>
                <!--Luminosidade-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="..\mapa\src\components\mapa\stationIcon\Luminosidade.svg"
                      alt="luminosidade"
                    />
                  </div>
                </SplideSlide>

                <!--Delta-T-->
                <SplideSlide>
                  <div class="slideStyle">
                    <img
                      src="../mapa/src/components/mapa/stationIcon/deltat/IDeltaT.svg"
                      alt="Delta-T"
                    />
                  </div>
                </SplideSlide>
              </Splide>
              <div
                style="margin-top: 2px; margin-right: 12px; text-align: center"
              >
                <span> {{ carrocelInfo }} </span>
                <span
                  v-if="carrocelInfo === 'Delta-T'"
                  style="
                    display: inline-block;
                    margin-left: 5px;
                    background-color: crimson;
                    color: #FFF;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 2px 8px;
                    border-radius: 12px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                  "
                >
                  Beta
                </span>
              </div>
            </div>
            <div v-show="this.station.nome == 'Sonda Hidrostática'">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  style="
                    width: 40px;
                    height: 40px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    border: 2px solid #53703c;
                    background: #53703c;
                    display: grid;
                    place-items: center;
                  "
                >
                  <img
                    src="..\mapa\src\components\mapa\stationIcon\NivelLiquido.svg"
                    alt="nivel"
                  />
                </div>
              </div>
              <div style="text-align: center">
                <span>Sonda nível</span>
              </div>
            </div>
          </div>
        </div>

        <!--grafico-->
        <div v-if="hour && (carrocelInfo !== 'Delta-T')">
          <div class="clima-card">
            <div class="mini-card">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div style="margin-bottom: 5px; width: 38%">
                  <span
                    style="
                      margin-left: 3px;
                      font-weight: 600;
                      font-size: medium;
                    "
                    >Histórico</span
                  >
                </div>
                <div style="display: flex; justify-content: space-around">
                  <span style="margin-right: 5px">
                    {{
                      new Date(hour.dateTime).toLocaleString().split(", ")[0]
                    }}
                  </span>
                </div>
              </div>
              <canvas ref="grafico_clima" width="320" height="175"></canvas>
            </div>
          </div>
        </div>
        <div></div>

        <div v-if="carrocelInfo === 'Delta-T'" class="clima-card">
          <div class="mini-card">
            <div class="delta-t-container">
              <div class="delta-t-section">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div style="margin-bottom: 5px; width: 50%">
                    <span
                      style="
                        margin-left: 3px;
                        font-weight: 600;
                        font-size: medium;
                      "
                    >
                    Gráfico Delta-T
                    </span>
                  </div>
                  <div style="display: flex; justify-content: space-around; width: 50%">
                    <span style="margin-right: 5px; margin-left: 25px; align-content: center">
                      {{ new Date(hour.dateTime).toLocaleString('pt-BR').split(", ")[0] }}
                    </span>
                    <span style="margin-left: 2px; align-content: center">
                      {{
                        new Date(hour.dateTimeLeitura).toLocaleTimeString('pt-BR', {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}
                    </span>
                  </div>
                </div>
                
                <div class="image-wrapper">
                  <img
                    src="../mapa/src/components/mapa/stationIcon/deltat/grid.png"
                    alt="Delta-T Gráfico"
                    ref="chartImage"
                  />
                  <div
                    v-if="deltaT.value !== null"
                    class="delta-t-point"
                    :style="{
                      top: this.deltaT.pointY + 'px',
                      left: this.deltaT.pointX + 'px'
                    }"
                  >
                    <span>
                      <img :src="require(`../mapa/src/components/mapa/stationIcon/deltat/${deltaT.windDirection}.svg`)" alt="direcao" />
                    </span>
                  </div>
                </div>

                <div class="delta-t-info">
                  <h2 class="delta-t-value">{{ deltaT.value }}</h2>
                  <p class="status">{{ deltaT.status }}</p>
                  <p class="wind-direction">Vento ao {{ deltaT.windDirection }}</p>
                </div>

                <div class="weather-icons">
                  <div class="icon-item">
                    <img src="..\mapa\src\components\mapa\stationIcon\VentoBlack.svg" alt="Vento" />
                    <span>{{ deltaT.windSpeed }} km/h</span>
                  </div>
                  <div class="icon-item">
                    <img src="..\mapa\src\components\mapa\stationIcon\RajadaVentoBlack.svg" alt="Vento" />
                    <span>{{ deltaT.gustSpeed }} km/h</span>
                  </div>
                </div>

                <div class="weather-icons">
                  <div class="icon-item">
                    <img src="..\mapa\src\components\mapa\stationIcon\TemperaturaBlack.svg" alt="Temperatura" />
                     <span>{{ deltaT.temperature }} °C</span>
                  </div>
                  <div class="icon-item">
                    <img src="..\mapa\src\components\mapa\stationIcon\UmidadeBlack.svg" alt="Umidade" />
                     <span>{{ deltaT.humidity }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--tabela-->
        <div class="summary-table">
          <div style="display: flex">
            <!--station-->
            <div style="width: 100%" class="tabela-horario tabela-cabecalho">
              Hora
            </div>

            <!---->
            <div
              v-if="this.carrocelInfo == 'Delta-T'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Temp
              <span style="font-size: 10px">(ºC)</span>
            </div>

            <div
              v-if="this.carrocelInfo == 'Delta-T'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Umid
              <span style="font-size: 10px">(%)</span>
            </div>
            
            <div
              v-if="this.carrocelInfo == 'Delta-T'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Vento
              <span style="font-size: 10px">(km/h)</span>
            </div>

            <div
              v-if="this.carrocelInfo == 'Delta-T'"
              style="width: 19%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Direção
            </div>

            <!---->
            <div
              v-if="this.carrocelInfo == 'Estação, dados agrupados'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Temp
              <span style="font-size: 10px">(ºC)</span>
            </div>

            <div
              v-if="this.carrocelInfo == 'Estação, dados agrupados'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Chuva
              <span style="font-size: 10px">(mm)</span>
            </div>

            <div
              v-if="this.carrocelInfo == 'Estação, dados agrupados'"
              style="width: 20%"
              class="tabela-info tabela-cabecalho"
            >
              Vento
              <span style="font-size: 10px">(km/h)</span>
            </div>

            <div
              v-if="this.carrocelInfo == 'Estação, dados agrupados'"
              style="width: 19%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Umid
              <span style="font-size: 10px">(%)</span>
            </div>

            <!---->
            <div
              v-if="this.carrocelInfo == 'Temperatura'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              ºC
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Umidade'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              %
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Barômetro'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              <div style="display: flex">hpa</div>
            </div>

            <div
              v-if="this.carrocelInfo == 'Barômetro'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              <div style="display: flex">mmHg</div>
            </div>

            <div
              v-if="this.carrocelInfo == 'Barômetro'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              <div style="display: flex">atm</div>
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Velocidade vento'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              km/h
            </div>
            <div
              v-if="this.carrocelInfo == 'Velocidade vento'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              m/s
            </div>
            <div
              v-if="this.carrocelInfo == 'Velocidade vento'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Direção
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Rajada de vento'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              km/h
            </div>
            <div
              v-if="this.carrocelInfo == 'Rajada de vento'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              m/s
            </div>
            <div
              v-if="this.carrocelInfo == 'Rajada de vento'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Direção
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Chuva'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              mm
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'UVI'"
              style="width: 100%"
              class="tabela-info tabela-cabecalho"
            >
              Uv
            </div>
            <div
              v-if="this.carrocelInfo == 'UVI'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              UVI
            </div>
            <!---->
            <div
              v-if="this.carrocelInfo == 'Luminosidade'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Lux
            </div>
            <!--Sonda-->
            <div
              v-if="this.carrocelInfo == 'Sonda nível'"
              style="width: 100%; border-radius: 0px 10px 0px 0px"
              class="tabela-info tabela-cabecalho"
            >
              Profundidade
              <span style="font-size: 10px">(metros)</span>
            </div>
          </div>
          <div v-if="info">
            <div
              class="summary-table-content"
              v-for="(d, i) in info.days[this.active].hours"
              :key="i"
            >
              <div class="text-center">
                <div class="row">
                  <div style="display: flex">
                    <div
                      v-if="
                        carrocelInfo == 'Temperatura' ||
                        carrocelInfo == 'Umidade' ||
                        carrocelInfo == 'Chuva' ||
                        carrocelInfo == 'Luminosidade' ||
                        carrocelInfo == 'Sonda nível'
                      "
                      style="
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{
                        new Date(d.dateTime).toLocaleTimeString('pt-BR', {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}
                    </div>

                    <div
                      v-if="carrocelInfo == 'Delta-T'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 18%">
                        {{
                          new Date(d.dateTime).toLocaleTimeString('pt-BR', {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}
                      </span>
                      <span style="width: 19%">{{
                        d.temperature.toFixed(2)
                      }}</span>
                      <span style="width: 17%">{{
                        d.humidity
                      }}</span>
                      <span style="width: 19%">{{
                        d.windSpeed.toFixed(2)
                      }}</span>
                      <span style="width: 15%">
                        <img :src="require(`../mapa/src/components/mapa/stationIcon/direcao/${d.windDirection}.svg`)" alt="direcao" />
                      </span>
                    </div>

                    <div
                      v-if="carrocelInfo == 'Estação, dados agrupados'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 18%">
                        {{
                          new Date(d.dateTime).toLocaleTimeString('pt-BR', {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}
                      </span>
                      <span style="width: 19%">{{
                        d.temperature.toFixed(2)
                      }}</span>
                      <span style="width: 17%">{{
                        d.rainfall.toFixed(2)
                      }}</span>
                      <span style="width: 19%">{{
                        d.windSpeed.toFixed(2)
                      }}</span>
                      <span style="width: 15%"> {{ d.humidity }}</span>
                    </div>
                    <div
                      v-if="carrocelInfo == 'Temperatura'"
                      style="
                        margin-left: 25px;
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{ d.temperature.toFixed(2) }}
                    </div>
                    <div
                      v-if="carrocelInfo == 'Umidade'"
                      style="
                        margin-left: 25px;
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{ d.humidity }}
                    </div>
                    <div
                      v-if="carrocelInfo == 'Barômetro'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 21%">
                        {{
                          new Date(d.dateTime)
                            .toLocaleTimeString()
                            .substring(0, 5)
                        }}</span
                      >
                      <span style="width: 25%"> {{ d.pressureInHpa }} </span>
                      <span style="width: 25%">
                        {{ d.pressureInMmHg }}
                      </span>
                      <span style="width: 20%"> {{ d.pressureInAtm }} </span>
                    </div>
                    <div
                      v-if="carrocelInfo == 'Velocidade vento'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 20%">
                        {{
                          new Date(d.dateTime)
                            .toLocaleTimeString()
                            .substring(0, 5)
                        }}</span
                      >
                      <span style="width: 20%">
                        {{ d.windSpeed.toFixed(2) }}</span
                      >
                      <span style="width: 20%"
                        >{{ d.windSpeedInMps.toFixed(2) }}
                      </span>
                      <span style="width: 23%">
                        <img
                          :src="
                            require(`../mapa/src/components/mapa/stationIcon/direcao/${d.windDirection}.svg`)
                          "
                          alt="direcao"
                        />
                      </span>
                    </div>
                    <div
                      v-if="carrocelInfo == 'Rajada de vento'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 20%">
                        {{
                          new Date(d.dateTime)
                            .toLocaleTimeString()
                            .substring(0, 5)
                        }}</span
                      >
                      <span style="width: 20%">
                        {{ d.gustSpeed.toFixed(2) }}
                      </span>
                      <span style="width: 20%">
                        {{ d.gustSpeedInMps.toFixed(2) }}
                      </span>
                      <span style="width: 23%">
                        <img
                          :src="
                            require(`../mapa/src/components/mapa/stationIcon/direcao/${d.windDirection}.svg`)
                          "
                          alt="direcao"
                        />
                      </span>
                    </div>
                    <div
                      v-if="carrocelInfo == 'Chuva'"
                      style="
                        margin-left: 25px;
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{ d.rainfall.toFixed(2) }}
                    </div>
                    <div
                      v-if="carrocelInfo == 'UVI'"
                      style="
                        width: 100%;
                        display: flex;
                        height: 31px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <span style="width: 28%">
                        {{
                          new Date(d.dateTime)
                            .toLocaleTimeString()
                            .substring(0, 5)
                        }}</span
                      >
                      <span style="width: 10%">{{ d.uv }}</span>
                      <span style="width: 28%">{{ d.uvi }}</span>
                    </div>
                    <div
                      v-if="carrocelInfo == 'Luminosidade'"
                      style="
                        margin-left: 25px;
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{ d.light }}
                    </div>
                    <div
                      v-if="this.carrocelInfo == 'Sonda nível'"
                      style="
                        margin-left: 25px;
                        width: 50%;
                        display: flex;
                        height: 31px;
                        padding: 5px 15px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      {{ d.depth.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <!--botao-->
        <div style="text-align: center">
          <a href="/report">
            <LoadButton
              style="margin-top: 10px"
              ref="btn_delete"
              class="btn btn-success"
              text="Gerar Relatório"
            />
          </a>
        </div>
        <div
          class="w-100"
          style="text-align: center; color: rgb(0, 96, 53)"
        ></div>
        <!---->
      </div>
      <div v-else style="padding: 10px">
        <a
          href="https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c938084928a58930192906bd3df01e7"
          style="
            width: 100%;
            height: 100%;
            padding: 10px 10px 10px 10px;
            background: #78a64e;
            border-radius: 5px;
            overflow: hidden;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
            display: inline-flex;
          "
        >
          <img
            style="width: 98%"
            src="../../../public/img/AssinarPlano.png"
            alt="AssinarPlano"
          />
        </a>
      </div>
    </div>
    <div v-if="load" class="text-center m-auto p-5">
      <Load color="#006035"></Load>
    </div>
  </div>
</template>
<script>
import SelectData from "../../components/crud/input/SelectData.vue";
import LoadButton from "../../components/form/LoadButton.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/dist/css/themes/splide-default.min.css";
import Load from "../Load.vue";

export default {
  components: {
    SelectData,
    LoadButton,
    Splide,
    SplideSlide,
    Load,
  },

  data() {
    return {
      station: {
        nome: "Estação",
        linkname: "estacao",
      },
      carrocelInfo: "Estação, dados agrupados",
      info: null,
      hour: null,
      grafico: null,
      load: true,
      data: null,
      active: null,
      showGraph: false,
      qrCodeId: null,
      premium: null,
      deltaT: {
        value: null,
        status: null,
        windDirection: "N/A",
        temperature: null,
        windSpeed: null,
        humidity: null,
        gustSpeed: null,
        pointX: 32,
        pointY: 270,
      },
    };
  },
  computed: {
    currentStationData() {
      return this.info?.days?.[this.active]?.hours ?? [];
    },
  },
  methods: {
    getAdequado(deltaT) {
      if (deltaT < 1) {
        return "Ruim";
      } else if (deltaT >= 1 && deltaT < 2) {
        return "Regular";
      } else if (deltaT >= 2 && deltaT < 4) {
        return "Adequado";
      } else if (deltaT >= 4 && deltaT < 6) {
        return "Adequado";
      } else if (deltaT >= 6 && deltaT <= 8) {
        return "Adequado";
      } else if (deltaT >= 8 && deltaT < 10) {
        return "Regular";
      } else if (deltaT >= 10 && deltaT < 12) {
        return "Ruim";
      } else {
        return "Ruim";
      }
    },
    calculateDeltaT() {
      if (!this.info || this.active === null) return;

      const registrosDia = this.info.days[this.active]?.hours;
      if (registrosDia && registrosDia.length > 0) {
        const ultimoRegistro = registrosDia[registrosDia.length - 1];

        const { temperature, humidity, windDirection, windSpeed, gustSpeed } = ultimoRegistro;

        // Usar a mesma fórmula do Flutter para o bulbo úmido
        const pontoOrvalho = temperature * Math.atan(0.151977 * Math.sqrt(humidity + 8.313659)) +
                            Math.atan(temperature + humidity) -
                            Math.atan(humidity - 1.676331) +
                            0.00391838 * Math.pow(humidity, 1.5) * Math.atan(0.023101 * humidity) -
                            4.686035;

        // Delta-T (Temperatura - Ponto de Orvalho)
        this.deltaT.value = (temperature - pontoOrvalho).toFixed(2);
        this.deltaT.status = this.getAdequado(this.deltaT.value);
        this.deltaT.windDirection = windDirection;
        this.deltaT.temperature = temperature;
        this.deltaT.windSpeed = windSpeed;
        this.deltaT.humidity = humidity;
        this.deltaT.gustSpeed = gustSpeed;

        this.updateDeltaTPoint(this.deltaT.value);
      }
    },
    updateDeltaTPoint(deltaT) {
      if (!this.deltaT.temperature || !this.deltaT.humidity) return;

      // Definindo os intervalos usados no Flutter
      const temperaturaMin = 0;
      const temperaturaMax = 50;
      const umidadeMin = 10;
      const umidadeMax = 100;

      // Intervalo de coordenadas do eixo X (Temperatura)
      const valorMinX = 32; // Menor posição no eixo X
      const valorMaxX = 260; // Maior posição no eixo X

      // Intervalo de coordenadas do eixo Y (Umidade)
      const valorMinY = 270; // Menor posição no eixo Y
      const valorMaxY = 5; // Maior posição no eixo Y

      // Função de interpolação linear
      const interpolar = (valor, minEntrada, maxEntrada, minSaida, maxSaida) => {
        return minSaida + (maxSaida - minSaida) * ((valor - minEntrada) / (maxEntrada - minEntrada));
      };

      // Calcular as coordenadas usando interpolação linear
      this.deltaT.pointX = Math.max(
        valorMinX,
        Math.min(interpolar(this.deltaT.temperature, temperaturaMin, temperaturaMax, valorMinX, valorMaxX), valorMaxX)
      );
      this.deltaT.pointY = Math.max(
        valorMaxY,
        Math.min(interpolar(this.deltaT.humidity, umidadeMin, umidadeMax, valorMinY, valorMaxY), valorMinY)
      );
    },
    onSlideChange(splide) {
      switch (splide.index) {
        case 0:
          this.carrocelInfo = "Temperatura";
          break;
        case 1:
          this.carrocelInfo = "Estação, dados agrupados";
          break;
        case 2:
          this.carrocelInfo = "Umidade";
          break;
        case 3:
          this.carrocelInfo = "Barômetro";
          break;
        case 4:
          this.carrocelInfo = "Velocidade vento";
          break;
        case 5:
          this.carrocelInfo = "Rajada de vento";
          break;
        case 6:
          this.carrocelInfo = "Chuva";
          break;
        case 7:
          this.carrocelInfo = "UVI";
          break;
        case 8:
          this.carrocelInfo = "Luminosidade";
          break;
        case 9:
          this.carrocelInfo = "Delta-T";
          break;
      }
    },

    directionAbbreviation(direction) {
      switch (direction) {
        case "Leste":
          return "L";
        case "Nordeste":
          return "NE";
        case "Noroeste":
          return "NO";
        case "Norte":
          return "N";
        case "Oeste":
          return "O";
        case "Sudoeste":
          return "SO";
        case "Sul":
          return "S";
        case "Suldeste":
          return "SE";
        default:
          return "N/A";
      }
    },

    getDataFirstRender(estacaoId, data) {
      this.station.nome = "Estação";
      this.station.linkname = "estacao";
      this.setCarouselBySensor();
      this.getData(estacaoId, data);
      this.$refs.splide.go(1);
      this.$refs.splide.splide.refresh();
    },

    getData(estacaoId, data) {
      clearTimeout(this.next);
      this.data = data;
      this.load = true;
      this.next = setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
        this.info = null;
        this.active = null;
        this.urlGet(
          "/Station/GetStationById/" + estacaoId + "/" + this.station.linkname
        )
          .then((e) => {
            this.info = e.data;
            this.premium = e.data.premium;
            this.qrCodeId = estacaoId;
            var now = new Date(data).getDate();
            for (let i = 0; i < this.info.days.length; i++) {
              var d = new Date(this.info.days[i].date).getDate();
              if (d == now) {
                this.active = i;
                break;
              }
            }
            if (this.active == null) {
              this.active = this.info.days.length - 1;
            }
          })
          .catch((e) => {})
          .finally(() => {
            this.load = false;
          });
      }, 300);
    },

    updateChart() {
      if (!this.$refs.grafico_clima) {
        return;
      }

      var cor = "";
      var label = "";
      var type = "line";
      var labels = [];
      var data = [];
      var field = "";
      var min = 0;

      if (this.grafico) {
        // Destroi o gráfico anterior para evitar conflitos
        this.grafico.destroy();
        this.grafico = null;
      }

      switch (this.carrocelInfo) {
        case "Temperatura": {
          field = "temperature";
          label = "Temperatura (Cº)";
          cor = "#F49C18";
          break;
        }
        case "Umidade": {
          field = "humidity";
          label = "Umidade (%)";
          cor = "#3E97FF";
          break;
        }
        case "Barômetro": {
          field = "pressureInHpa";
          label = "Pressão (hPa)";
          cor = "#FF3E3E";
          min = null;
          break;
        }
        case "Velocidade vento": {
          field = "windSpeed";
          label = "Vento (km/h)";
          cor = "#727272";
          break;
        }
        case "Rajada de vento": {
          field = "gustSpeed";
          label = "Rajada de vento (km/h)";
          cor = "#10C537";
          break;
        }
        case "Chuva": {
          type = "bar";
          field = "rainfall";
          label = "Chuva (mm)";
          cor = "#BBD1FF";
          break;
        }
        case "UVI": {
          field = "uvi";
          label = "UVI";
          cor = "#E8D746";
          break;
        }
        case "Luminosidade": {
          field = "light";
          label = "Luminosidade (lux)";
          cor = "#839682";
          break;
        }
        case "Sonda nível": {
          field = "depthCalc";
          label = "Profundidade (m)";
          cor = "#495948";
          break;
        }
      }

      if (this.carrocelInfo === "Estação, dados agrupados") {
        var temperatures = [];
        var rainfalls = [];
        var windSpeeds = [];
        var humiditys = [];

        for (const h of this.info.days[this.active].hours) {
          labels.push(
            new Date(h.dateTime).toLocaleTimeString('pt-BR').substring(0, 2)
          );
          temperatures.push(h["temperature"]);
          rainfalls.push(h["rainfall"]);
          windSpeeds.push(h["windSpeed"]);
          humiditys.push(h["humidity"]);
        }

        var temperaturecor = "#F49C18";
        var rainfallcor = "#BBD1FF";
        var windSpeedcor = "#727272";
        var humiditycor = "#3E97FF";

        var dataset = [
          {
            fill: true,
            borderColor: rainfallcor,
            backgroundColor: rainfallcor + "99",
            label: "Chuva (mm)       ",
            data: rainfalls,
          },
          {
            fill: true,
            borderColor: windSpeedcor,
            backgroundColor: windSpeedcor + "99",
            label: "Vento (km/h) ",
            data: windSpeeds,
          },
          {
            fill: true,
            borderColor: temperaturecor,
            backgroundColor: temperaturecor + "99",
            label: "Temperatura (Cº)",
            data: temperatures,
          },
          {
            fill: true,
            borderColor: humiditycor,
            backgroundColor: humiditycor + "99",
            label: "Umidade (%)",
            data: humiditys,
          },
        ];

        if (!this.grafico) {
          try {
            this.grafico = new Chart(this.$refs.grafico_clima, {
              type: type,
              data: {
                labels: labels,
                datasets: dataset,
              },
              options: {
                animation: {
                  duration: 300,
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: min == 0,
                        min: min == null ? undefined : min,
                      },
                    },
                  ],
                },
              },
            });

            //this.grafico.update();
          } catch (ex) {
            //console.error(ex);
          }
        } else {
          this.grafico.config.type = type;
          this.grafico.data.labels = labels;
          this.grafico.data.datasets = dataset;
          this.grafico.options.scales.yAxes[0].ticks.beginAtZero = min == 0;
          this.grafico.options.scales.yAxes[0].ticks.min =
            min == null ? undefined : min;
          this.grafico.update();
        }
      } else {
        for (const h of this.info.days[this.active].hours) {
          labels.push(
            new Date(h.dateTime).toLocaleTimeString('pt-BR').substring(0, 2)
          );
          data.push(h[field]);
        }

        if (!this.grafico) {
          try {
            this.grafico = new Chart(this.$refs.grafico_clima, {
              type: type,
              data: {
                labels: labels,
                datasets: [
                  {
                    fill: true,
                    borderColor: cor,
                    backgroundColor: cor + "99",
                    label: label,
                    data: data,
                  },
                ],
              },
              options: {
                animation: {
                  duration: 300,
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: min == 0,
                        min: min == null ? undefined : min,
                      },
                    },
                  ],
                },
              },
            });
          } catch (ex) {
            //console.error(ex);
          }
        } else {
          this.grafico.config.type = type;
          this.grafico.data.labels = labels;
          this.grafico.data.datasets = [
            {
              fill: true,
              borderColor: cor,
              backgroundColor: cor + "99",
              label: label,
              data: data,
            },
          ];
          this.grafico.options.scales.yAxes[0].ticks.beginAtZero = min == 0;
          this.grafico.options.scales.yAxes[0].ticks.min =
            min == null ? undefined : min;
          this.grafico.update();
        }
      }

      //  debugger;
      //if (!this.grafico) {
    },

    updateHour() {
      var now = new Date().getHours();
      var d = null;
      for (const day of this.info.days[this.active].hours) {
        var t = new Date(day.data).getHours();
        if (t > now) {
          break;
        }
        d = day;
      }
      if (d == null) {
        d = this.info.days[this.active].hours[0];
      }
      this.hour = d;
      setTimeout(() => {
        this.updateChart();
      }, 10);
    },

    beforeUnmount() {
      setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
      }, 300);
    },

    formatNumber(number) {
      return number.toString().split(".")[0];
    },

    setCarouselBySensor() {
      switch (this.station.nome) {
        case "Estação":
          this.carrocelInfo = "Estação, dados agrupados";
          this.station.linkname = "estacao";
          this.getData(this.qrCodeId);
          break;
        case "Sonda Hidrostática":
          this.carrocelInfo = "Sonda nível";
          this.station.linkname = "sonda-hidrostatica";
          this.getData(this.qrCodeId);
      }
    },
  },

  watch: {
    carrocelInfo() {
      this.$nextTick(() => {
        this.updateChart();
      });
    },
    active(a) {
      if (a != null) {
        this.updateHour();
        this.calculateDeltaT();
      }
    },
  },
};
</script>

<style>
.delta-t-container {
  display: flex;
  justify-content: center;
  padding: 0px 5px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
  width: 280px;
  height: 280px;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
}
.delta-t-point {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.delta-t-info {
  text-align: center;
  margin-top: 12px;
}
.delta-t-info h2, p {
  margin: 0px;
}
.delta-t-value {
  font-size: 24px;
  font-weight: bold;
}
.status {
  font-size: 16px;
  font-weight: bold;
}
.wind-direction {
  font-size: 14px;
}
.weather-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.icon-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.icon-item img {
  margin-right: 5px;
}
.previsao-item {
  width: 16%;
  text-align: center;
}
.icons {
  border-radius: 5px;
  border: 2px solid var(--Branco-75, #d1d1d1);
  background: var(--Branco, #fff);
  height: 40px;
  width: 40px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.icon-text {
  text-align: center;
  color: black;
  margin-top: 7px;
}
.mini-card {
  background-color: #ffffff;
  padding: 6px;
  border-radius: 10px;
  /*box-shadow: 0px 0px 0.2em #000000;*/
}
.texto-dados-clima-min-max {
  font-size: 14px;
  display: block;
  margin-left: 5px;
  font-weight: 600;
}
.mini-text {
  font-size: 10px;
  margin-top: -7px;
  color: black;
}
.splide__slide.is-active .slideStyle {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid #53703c;
  background: #53703c;
  display: grid;
  place-items: center;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2);
}
.splide__slide:not(.is-active) .slideStyle {
  background-color: #b0b0b0;
  border: 2px solid var(--Branco-75, #b0b0b0);
}
.splide__arrow {
  border-radius: 5px;
  background-color: #78a64e;
  margin-top: 40px;
}
.splide__arrow--next {
  right: 0.1em;
  margin-right: 10px;
}
.splide__arrow--prev {
  left: 0.1em;
  margin-right: 20px;
}
.splide__arrow:not(:disabled) {
  opacity: 1;
}
.splide__arrow:hover:not(:disabled) {
  opacity: 1;
}
.splide__track--nav .splide__list .splide__slide.is-active {
  border: none;
  outline: none;
}
.splide__arrow svg {
  fill: white;
}
.splide__slide .slideStyle {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 2px solid var(--Branco-75, #d1d1d1);
  background: #ffffff;
  display: grid;
  place-items: center;
}
.summary-table-content:nth-child(odd) {
  background-color: #f2f2f2;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.summary-table-content:nth-child(even) {
  background-color: #ffffff;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.summary-table {
  margin-right: 10px;
  margin-left: 10px;
}
.summary-table-content:last-child {
  border-radius: 0px 0px 10px 10px;
}
.tabela-horario {
  border-radius: 10px 0px 0px 0px;
  background: #53703c;
}
.tabela-info {
  background: #53703c;
}
.tabela-cabecalho {
  display: flex;
  height: 40px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: white;
}
</style>
